import React from "react";
import {
  ShieldCheckIcon,
  LockClosedIcon,
  SearchCircleIcon,
  UserGroupIcon,
  SparklesIcon,
  CheckIcon,
} from "@heroicons/react/outline";
import HomeBgVideo from "../../assets/video/security_video.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const SecurityMain = () => {
  return (
    <div className="text-white min-h-screen flex flex-col">
      <section className="py-20">
        <div className="container mx-auto px-6 md:px-12">
          <h2 className="text-4xl font-extrabold text-center text-orange-500 mb-4">
            Our Core Services For Cyber Security
          </h2>
          <p className="text-lg text-center text-gray-400 max-w-2xl mx-auto mb-12">
            We provide a comprehensive suite of services to protect your digital environment.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                Icon: ShieldCheckIcon,
                title: "Threat Detection",
                description:
                  "Proactive monitoring and early detection of potential cyber threats.",
              },
              {
                Icon: LockClosedIcon,
                title: "Data Encryption",
                description:
                  "Secure your sensitive data with state-of-the-art encryption technologies.",
              },
              {
                Icon: SearchCircleIcon,
                title: "Security Audits",
                description:
                  "Comprehensive assessments to uncover vulnerabilities in your infrastructure.",
              },
            ].map((service, index) => (
              <div
                key={index}
                className="p-8 bg-gray-800 rounded-lg text-center transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-lg hover:shadow-xl"
              >
                <service.Icon className="w-16 h-16 mx-auto text-orange-500 mb-6" />
                <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                <p className="text-gray-400">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="pt-10 md:pt-20 pb-10 md:pb-30 px-4">
        <div className="container mx-auto flex flex-col md:flex-row">
          <div className="md:w-1/2 mb-6 md:mb-0">
            <video
              src={HomeBgVideo}
              alt="Agency Image"
              className="w-full"
              autoPlay
              loop
              muted
            />
          </div>

          <div className="md:w-1/2 md:pl-10">
            <h1 className="text-4xl font-bold mt-5 text-gray-400">
            In an era where data breaches and cyber-attacks are more frequent and sophisticated,
            ensuring the security of your business's digital assets is critical.
            </h1>
            <h1 className="text-2xl font-semibold mb-8 mt-8 text-orange-500">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> We are the
              perfect solution
            </h1>
            <p className="text-2xl text-gray-400 mb-6">
            At Codesign LLC, we provide a full suite of cyber security services tailored to meet the
            unique needs of your organization. 
            </p>
          </div>
        </div>
      </section>
      <section className="py-20 bg-gray-800">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h2 className="text-4xl font-extrabold text-orange-500 mb-6">
            Why Partner With Us?
          </h2>
          <p className="text-lg text-gray-400 max-w-3xl mx-auto mb-12">
            Our team is dedicated to providing robust cybersecurity solutions. We leverage advanced tools and strategies to ensure your systems remain secure against evolving threats.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                Icon: UserGroupIcon,
                title: "Expert Team",
                description:
                  "Our specialists are industry veterans with deep knowledge of cybersecurity.",
              },
              {
                Icon: SparklesIcon,
                title: "Innovative Tools",
                description:
                  "We use cutting-edge technologies to defend against the latest cyber threats.",
              },
              {
                Icon: CheckIcon,
                title: "Proven Success",
                description:
                  "Hundreds of satisfied clients trust us to protect their digital assets.",
              },
            ].map((reason, index) => (
              <div
                key={index}
                className="p-8 bg-gray-700 rounded-lg text-center transform hover:-translate-y-2 hover:bg-gray-600 transition duration-300 shadow-lg hover:shadow-xl"
              >
                <reason.Icon className="w-16 h-16 mx-auto text-orange-500 mb-6" />
                <h3 className="text-2xl font-bold mb-4">{reason.title}</h3>
                <p className="text-gray-400">{reason.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-20">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h2 className="text-4xl font-extrabold text-orange-500 mb-6">
            Client Testimonials
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                quote:
                  "Codesign Ltd. has been a game-changer for our business. Their proactive approach has kept us safe from numerous threats.",
                name: "Arsalan Khan, Chief Co-ordinator, AA Fitness Pvt. Ltd.",
                websiteLink: "https://www.chainofakmal.com/",
              },
              {
                quote:
                  "The expertise and dedication of their team are unparalleled. We’ve seen a significant improvement in our security posture.",
                name: "Saif Merchant, Chief Technology Officer (CTO), Tidynodes Inc.",
                websiteLink: "https://tidynodes.com/",
              },
              {
                quote:
                  "Thanks to Codesign Ltd., we can focus on our core business, knowing our digital infrastructure is secure.",
                name: "Bilal Elahi, Chief Operating Officer (COO), Brandliners Inc",
                websiteLink: "https://brandliners.com/",
              },
            ].map((testimonial, index) => (
              <div
                key={index}
                className="p-8 bg-gray-800 rounded-lg text-left transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-lg hover:shadow-xl"
              >
                <p className="text-gray-400 mb-4">{testimonial.quote}</p>
                <p className="font-bold text-orange-500 mb-2">{testimonial.name}</p>
                <a
                  href={testimonial.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold text-gray-400 hover:underline"
                >
                  {testimonial.websiteLink}
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <section className="py-20">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h2 className="text-4xl font-extrabold text-orange-500 mb-6">
            Client Testimonials
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                quote:
                  "CyberSecurity Co. has been a game-changer for our business. Their proactive approach has kept us safe from numerous threats.",
                name: "Arsalan Khan, Chief Co-ordinator, AA Fitness Pvt. Ltd.",
                websiteLink: "https://www.chainofakmal.com/",
              },
              {
                quote:
                  "The expertise and dedication of their team are unparalleled. We’ve seen a significant improvement in our security posture.",
                name: "Saif Merchant, Chief Technology Officer (CTO), Tidynodes Inc.",
                websiteLink: "https://tidynodes.com/",
              },
              {
                quote:
                  "Thanks to CyberSecurity Co., we can focus on our core business, knowing our digital infrastructure is secure.",
                name: "Bilal Elahi, Chief Operating Officer (COO), Brandliners Inc",
                websiteLink: "https://brandliners.com/",
              },
            ].map((testimonial, index) => (
              <div
                key={index}
                className="p-8 bg-gray-800 rounded-lg text-left transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-lg hover:shadow-xl"
              >
                <p className="text-gray-400 mb-4">{testimonial.quote}</p>
                <p className="font-bold text-orange-500 mb-2">{testimonial.name}</p>
                <p className="font-semibold text-gray-400">{testimonial.websiteLink}</p>
              </div>
            ))}
          </div>
        </div>
      </section> */}
      <section className="py-20 bg-gradient-to-r from-orange-600 to-orange-800">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h2 className="text-4xl font-extrabold mb-4 text-white">
            Ready to Secure Your Business?
          </h2>
          <p className="text-lg text-gray-200 max-w-2xl mx-auto mb-8">
            Reach out today to learn more about our comprehensive cybersecurity services.
          </p>
          <button className="bg-white text-orange-600 py-4 px-8 rounded-full text-lg font-bold hover:bg-gray-100 transition duration-300 shadow-lg hover:shadow-xl">
            Get Started
          </button>
        </div>
      </section>
    </div>
  );
};

export default SecurityMain;



// import React from "react";
// import {
//   ShieldCheckIcon,
//   LockClosedIcon,
//   SearchCircleIcon,
//   UserGroupIcon,
//   SparklesIcon,
//   CheckIcon,
// } from "@heroicons/react/outline";

// const CyberSecurityLanding = () => {
//   return (
//     <div className="bg-gray-900 text-white min-h-screen">
//       <section className="py-24">
//         <div className="container mx-auto px-6 md:px-12">
//           <h2 className="text-4xl font-bold text-center text-orange-500 mb-12">
//             Our Core Cybersecurity Services
//           </h2>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
//             {[
//               {
//                 Icon: ShieldCheckIcon,
//                 title: "Threat Detection",
//                 description:
//                   "Proactive monitoring and early detection of potential cyber threats.",
//               },
//               {
//                 Icon: LockClosedIcon,
//                 title: "Data Encryption",
//                 description:
//                   "Secure your sensitive data with state-of-the-art encryption technologies.",
//               },
//               {
//                 Icon: SearchCircleIcon,
//                 title: "Security Audits",
//                 description:
//                   "Comprehensive assessments to uncover vulnerabilities in your infrastructure.",
//               },
//             ].map((service, index) => (
//               <div
//                 key={index}
//                 className="p-8 bg-gray-800 rounded-lg text-center transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-xl hover:shadow-2xl"
//               >
//                 <service.Icon className="w-16 h-16 mx-auto text-orange-500 mb-6" />
//                 <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
//                 <p className="text-gray-300">{service.description}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <section className="py-24 bg-gray-900">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-bold text-orange-500 mb-12">
//             Why Choose Us?
//           </h2>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
//             {[
//               {
//                 Icon: UserGroupIcon,
//                 title: "Expert Team",
//                 description:
//                   "Our specialists are industry veterans with deep knowledge of cybersecurity.",
//               },
//               {
//                 Icon: SparklesIcon,
//                 title: "Innovative Tools",
//                 description:
//                   "We use cutting-edge technologies to defend against the latest cyber threats.",
//               },
//               {
//                 Icon: CheckIcon,
//                 title: "Proven Success",
//                 description:
//                   "Hundreds of satisfied clients trust us to protect their digital assets.",
//               },
//             ].map((reason, index) => (
//               <div
//                 key={index}
//                 className="p-8 bg-gray-800 rounded-lg text-center transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-xl hover:shadow-2xl"
//               >
//                 <reason.Icon className="w-16 h-16 mx-auto text-orange-500 mb-6" />
//                 <h3 className="text-2xl font-semibold mb-4">{reason.title}</h3>
//                 <p className="text-gray-300">{reason.description}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <section className="py-24 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-bold text-orange-500 mb-12">
//             What Our Clients Say
//           </h2>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
//             {[
//               {
//                 quote:
//                   "CyberSecurity Co. has been a game-changer for our business. Their proactive approach has kept us safe from numerous threats.",
//                 name: "John Doe, CEO",
//               },
//               {
//                 quote:
//                   "The expertise and dedication of their team are unparalleled. We’ve seen a significant improvement in our security posture.",
//                 name: "Jane Smith, CTO",
//               },
//               {
//                 quote:
//                   "Thanks to CyberSecurity Co., we can focus on our core business, knowing our digital infrastructure is secure.",
//                 name: "Mark Wilson, CFO",
//               },
//             ].map((testimonial, index) => (
//               <div
//                 key={index}
//                 className="p-8 bg-gray-800 rounded-lg text-left transform hover:-translate-y-2 hover:bg-gray-700 transition duration-300 shadow-xl hover:shadow-2xl"
//               >
//                 <p className="text-gray-300 mb-4 italic">"{testimonial.quote}"</p>
//                 <p className="font-bold text-orange-500">{testimonial.name}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <section className="py-24 bg-gradient-to-r from-orange-600 to-orange-800">
//         <div className="container mx-auto px-6 md:px-12 text-center">
//           <h2 className="text-4xl font-extrabold mb-4 text-white">
//             Ready to Secure Your Business?
//           </h2>
//           <p className="text-lg text-gray-200 max-w-2xl mx-auto mb-8">
//             Reach out today to learn more about our comprehensive cybersecurity services.
//           </p>
//           <button className="bg-white text-orange-600 py-4 px-10 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300 shadow-lg hover:shadow-xl">
//             Get Started
//           </button>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default CyberSecurityLanding;






